import React from "react";
import Link from "next/link";
import { useDispatch, useSelector } from "react-redux";
import FooterList from "./FooterList";
import { SocialIcon } from "react-social-icons";
import
{
  PhoneIcon,
  MailIcon,
  LocationMarkerIcon,
} from "@heroicons/react/outline";
import
{
  HomesAddress,
  HomesAddressLink,
  HomesEmail,
  HomesPhone,
  serverAPI,
} from "../utils/envConfig";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { fetchPropTypes } from "../redux/actions/property";
import axios from "axios";
import { useState } from "react";
import Image from "next/image";

function backToTop ()
{
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}
const Footer = () =>
{
  const router = useRouter();
  const [ propType, setPropType ] = useState( [] );
  const getProptypes = async () =>
  {
    try
    {
      const { data } = await axios.get( `${ serverAPI }/propType` );
      setPropType( data );
    } catch ( err ) { }
  };
  useEffect( () =>
  {
    getProptypes();
  }, [] );
  const normalImage = '/img/linkedin-gold.svg';
  const hoverImage = '/img/linkedin-navy.svg';
  const normalImageYoutube = '/img/youtube-gold.svg';
  const hoverImageYoutube = '/img/youtube-navy.svg';
  const normalImageInstagram = '/img/instagram-gold.svg';
  const hoverImageInstagram = '/img/instagram-navy.svg';
  const hoverImageFacebook = '/img/facebook-navy.svg';
  const normalImageFacebook = '/img/facebook-gold.svg';
  console.log();
  return (
    <div className="h-auto  text-white lg:bg-footer-bg lg:bg-transparent bg-[#263250] lg:bg-no-repeat lg:bg-cover  lg:-mt-24 ">
      <div className="max-w-[1600px] mx-auto">


        <div className="pt-5 lg:pt-10 grid grid-col-1 lg:justify-start 
  justify-center lg:mt-8 lg:mx-20 md:mx-12">
          <Link href="/">
            <Image
              src="/img/logo-gold-h.png"
              alt="Logo"
              width={ 200 }
              height={ 80 }
              className="h-[5rem] object-cover"
              loading="lazy"
            />
          </Link>
        </div>
        <div className="block xs:grid grid-cols-3 h-full  lg:grid-cols-5 xl:grid-cols-9 2xl:grid-cols-9  md:grid-cols-2 
      justify-evenly gap-8 lg:gap-0 xl:justify-start items-start  xl:justify-items-center
       overflow-hidden lg:mt-8  md:mt-0 md:mb-12 mb-12  lg:mx-20 md:mx-12 mx-4 sm:mx-8 lg:pl-0 ">
          <div className="col-span-4 md:col-span-3 lg:col-span-3 grid grid-cols-1 gap-0 
         my-2 xs:my-0">
            <p className=" text-white text-justify md:pl-12 lg:pl-0">
              Alba Homes Real Estate Broker LLC is one of the most famous real
              estate company in Dubai, UAE. We provide a diverse selection of
              properties, such as apartments, villas, office properties, and
              commercial spaces, all at competitive prices. The staff at Alba
              Homes are very experienced and skilled, and they work directly with
              customers to assist them in finding the ideal house to meet their
              needs. If you are searching for an apartment, villa, store, office,
              or investment opportunity in Dubai, United Arab Emirates, Alba Homes
              Real Estate Broker LLC should be your first pick.
            </p>
            <div className="grid grid-cols-1 mt-5 md:pl-12 lg:pl-0">
              <h3 className="text-xl font-bold truncate text-[#ffdeab] mb-5 ">Get in Touch!</h3>
              <div className="flex flex-col gap-4">
                <div className="flex flex-row gap-2">
                  <PhoneIcon className="h-6 w-6" />
                  <p className="text-whitetruncate hover:underline cursor-pointer">
                    <a href={ `tel:${ HomesPhone }` }>{ HomesPhone }</a>
                  </p>
                </div>
                <div className="flex flex-row gap-2">
                  <MailIcon className="h-6 w-6" />
                  <p className="text-whitetruncate hover:underline cursor-pointer">
                    <a href="mailto:info@alba.homes">{ HomesEmail }</a>
                  </p>
                </div>
                <div className="flex flex-row gap-2 p">
                  <LocationMarkerIcon className="h-6 w-6 flex-none" />
                  <a
                    href={ HomesAddressLink }
                    target="_blank"
                    rel="noreferrer"
                    className="flex flex-wrap text-white "
                  >
                    { HomesAddress }
                  </a>
                </div>
              </div>

              <h3 className="text-xl font-medium truncate my-5">Follow Us</h3>
              <div className="flex  flex-col gap-4 col-span-1">
                <div className="text-sm flex flex-row flex-wrap lg:gap-4 gap-4 whitespace-nowrap">
                  <Link href="https://www.facebook.com/albahomesuae" passHref>
                    <a
                      className="h-9 w-9"
                      rel="noreferrer noopener"
                      target="_blank"
                    >
                      <div className="relative h-9 w-9">
                        <img
                          src={ normalImageFacebook }
                          alt="Facebook"
                          width={ 32 }
                          height={ 32 }
                          objectFit="cover"
                          loading="lazy"
                          className="transition-all duration-300"
                          onMouseEnter={ ( e ) => e.currentTarget.src = hoverImageFacebook }
                          onMouseLeave={ ( e ) => e.currentTarget.src = normalImageFacebook }
                        />
                      </div>
                    </a>
                  </Link>
                  <Link href="https://www.instagram.com/albahomesuae/" passHref>
                    <a
                      className="h-9 w-9"
                      rel="noreferrer noopener"
                      target="_blank">
                      <div className="relative h-9 w-9">
                        <img
                          src={ normalImageInstagram }
                          alt="instagram"
                          width={ 32 }
                          height={ 32 }
                          objectFit="cover"
                          loading="lazy"
                          className="transition-all duration-300"
                          onMouseEnter={ ( e ) => e.currentTarget.src = hoverImageInstagram }
                          onMouseLeave={ ( e ) => e.currentTarget.src = normalImageInstagram }
                        />
                      </div>
                    </a>
                  </Link>
                  <Link href="https://www.youtube.com/@AlbaHomesuae/" passHref>
                    <a
                      className="h-9 w-9"
                      rel="noreferrer noopener"
                      target="_blank">
                      <div className="relative h-9 w-9">
                        <img
                          src={ normalImageYoutube }
                          alt="youtube"
                          width={ 32 }
                          height={ 32 }
                          objectFit="cover"
                          loading="lazy"
                          className="transition-all duration-300"
                          onMouseEnter={ ( e ) => e.currentTarget.src = hoverImageYoutube }
                          onMouseLeave={ ( e ) => e.currentTarget.src = normalImageYoutube }
                        />
                      </div>
                    </a>
                  </Link>
                  <Link href="https://www.linkedin.com/company/alba-homes/" passHref>
                    <a
                      className="h-9 w-9"
                      rel="noreferrer noopener"
                      target="_blank">
                      <div className="relative h-9 w-9">
                        <img
                          src={ normalImage }
                          alt="linkedin"
                          width={ 32 }
                          height={ 32 }
                          objectFit="cover"
                          className="transition-all duration-300"
                          loading="lazy"
                          onMouseEnter={ ( e ) => e.currentTarget.src = hoverImage }
                          onMouseLeave={ ( e ) => e.currentTarget.src = normalImage }
                        />
                      </div>
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="4xs:hidden lg:grid grid grid-cols-1 gap-4 md:pl-12 lg:col-span-2 my-4 xs:my-0">
            <h3 className="text-xl font-bold truncate md:col-span-1 text-[#ffdeab]">
              Features
            </h3>
            <FooterList link={ `/` } name={ "Home" } />
            <FooterList link={ `/find-the-properties` } name={ "All Properties" } />
            <FooterList link={ `/new-listing` } name={ "Rent or Sell Your Property" } />
            <FooterList link={ `/contact-us` } name={ "Contact us" } />
            <FooterList link={ `/login` } name={ "Login" } />
            <FooterList link={ `/register` } name={ "Register" } />
            <FooterList link={ `/blogs` } name={ "Relevant Articles" } />
            <FooterList link={ `https://www.alba.jobs/` } target="_blank" name={ "Careers" } />
          </div>

          <div className="4xs:hidden xl:block  col-span-4  my-8 xs:my-0">
            <h3 className="text-xl font-bold truncate text-[#ffdeab]">Popular Searches</h3>
            <div className="grid grid-cols-2 gap-x-10 gap-y-5 pt-4">
              { propType &&
                propType.length > 0 &&
                propType.map( ( p, index ) => (
                  <Link
                    key={ p.id }
                    href={ `/find-the-properties?filter=${ Buffer.from( JSON.stringify( { propertyType: { label: p.propertyType, value: p.id, name: "propertyType", title: 'Property Type' } } ) ).toString( 'base64' ) }` }
                    passHref
                  >
                    <a className="text-whitetruncate hover:underline cursor-pointer">
                      { p.propertyType } for Sale in Dubai
                    </a>
                  </Link>
                ) ) }  </div>
          </div>
        </div>
        <hr className="border-1 border-gray-400 lg:mx-20 md:mx-12 mx-8 pb-10" />
        <div className="text-gray-400 lg:flex lg:flex-row grid-cols-1 gap-4 pt-0 flex flex-col md:pb-auto pb-10 justify-between items-center text-center lg:mx-20 md:mx-12 mx-8">
          <div className="text-sm  px-2 text-white">
            © { new Date().getFullYear() } ALBA HOMES REAL ESTATE BROKER L.L.C • All Rights Reserved
          </div>
          <div className="text-white">
            <Link href={ "/terms-and-conditions" } >Terms & Conditions</Link> •&nbsp;
            <Link href={ "/privacy-policy" }>Privacy policy</Link>
            {/* • */ }
            {/* Become a Broker */ }
          </div>
          <div className=" text-white">
            Powered by{ " " }
            {/* <Link href="https://albacorp.net">
                        <a target="_blank" rel="noopener noreferrer"> */}
            Alba Corporation
            {/* </a>
                    </Link> */}
          </div>
        </div>
        <div className="relative ">
          <button
            type="button"
            onClick={ () => backToTop() }
            data-mdb-ripple="true"
            data-mdb-ripple-color="light"
            className="absolute inline-block p-3 bg-[#c3a576] text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-[#081429] hover:shadow-lg focus:bg-[#c3a576] focus:shadow-lg focus:outline-none focus:ring-0 active:bg-[#081429] active:shadow-lg transition duration-150 ease-in-out bottom-8 right-8"
            id="btn-back-to-top"
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              className="w-4 h-4"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                fill="currentColor"
                d="M34.9 289.5l-22.2-22.2c-9.4-9.4-9.4-24.6 0-33.9L207 39c9.4-9.4 24.6-9.4 33.9 0l194.3 194.3c9.4 9.4 9.4 24.6 0 33.9L413 289.4c-9.5 9.5-30 9.3-34.3-.4L264 168.6V456c0 13.3-10.7 24-24 24h-32c-13.3 0-24-10.7-24-24V168.6L69.2 289.1c-9.3 9.8-24.8 10-34.3.4z"
              ></path>
            </svg>
          </button>
        </div>
      </div>      </div>
  );
};

export default Footer;
